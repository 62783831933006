
import Vue from 'vue'

import SettingsHeader from '../../settings/_SettingsHeader.vue'
import FormLabel from '@/components/patient/add-patient/shared/FormLabel.vue'
import DialogCard from '@/components/DialogCard.vue'
import AppSettingsItems from './_AppSettingsItems.vue'

import loadingState from '@/constants/loadingState'
import service from '@/services/app-service'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'
import AuditLog from '@/utils/audit/AuditLog'
import appTimeFormatOptions from '@/constants/appTimeFormatOptions'
import trialLanguagesMixin from '../../trialLanguagesMixin'
import currentAppSettingsMixin from '@/components/patient/app/settings/currentAppSettingsMixin'
import { selectedPatientMapActions } from '@/store/modules/selectedPatientModule'
import permissionsGettersMixin from '@/components/mixins/store/permissionsGettersMixin'
import ImgEditAppSettings from '@/components/images/ImgEditAppSettings.vue'
import BoxedToolbar from '@/components/layout/BoxedToolbar.vue'
import BoxedCard from '@/components/patient/settings/BoxedCard.vue'

const initialState = () => ({
  state: loadingState.INITIAL,
  auditLog: new AuditLog(),
  apiError: null,
})

export default Vue.extend({
  name: 'EditCurrentAppSettings',
  mixins: [
    trialLanguagesMixin,
    currentAppSettingsMixin,
    permissionsGettersMixin,
  ],
  components: {
    BoxedCard,
    BoxedToolbar,
    ImgEditAppSettings,
    DialogCard,
    FormLabel,
    SettingsHeader,
    AppSettingsItems,
  },
  props: {
    patientNo: { type: String, required: true },
    appInfo: { type: Object, required: true },
  },
  data() {
    return {
      ...initialState(),
      appLanguage: null,
      appTimeFormat: null,
      showDialog: false,
      showConfirmDialog: false,
      loadingState,
      appTimeFormatOptions,
      appLanguageRules: [v => !!v || 'App language is required'],
      appTimeFormatRules: [v => !!v || 'Patient sex is required'],
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
    formValid() {
      return !(
        this.appLanguage === this.appInfo.languageTag &&
        this.appTimeFormat === this.appInfo.clockNotation
      )
    },
  },
  methods: {
    ...selectedPatientMapActions(),
    openDialog() {
      Object.assign(this.$data, initialState())
      this.appLanguage = this.appInfo.languageTag
      this.appTimeFormat = this.appInfo.clockNotation

      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    addAuditEntry(fieldName, value) {
      this.auditLog.log(fieldName, value)
    },
    executeRequest() {
      this.state = loadingState.LOADING
      this.apiError = null

      const data = {
        patientNumber: this.patientNo,
        languageTag: this.appLanguage,
        clockNotation: this.appTimeFormat,
        currentClientTime: dateTimeWithTimeZone(),
        auditEntries: this.auditLog.getAuditEntries(),
        reason: 'Changed by HCP',
      }

      service
        .updateAppSettings(data)
        .then(() => {
          this.$emit('data-updated')

          this.showConfirmDialog = false
          this.closeDialog()
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.apiError = error

          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
})
