
import Vue from 'vue'
import currentAppSettingsMixin from '@/components/patient/app/settings/currentAppSettingsMixin'
import SettingsItem from '@/components/patient/settings/_SettingsItem.vue'

export default Vue.extend({
  name: 'AppSettingsItems',
  mixins: [currentAppSettingsMixin],
  props: {
    appInfo: { type: Object, required: true },
  },
  components: {
    SettingsItem,
  },
})
