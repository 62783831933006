import Vue from 'vue'
import trialLanguagesMixin from '@/components/patient/trialLanguagesMixin'
import appTimeFormatOptions from '@/constants/appTimeFormatOptions'

export default Vue.extend({
  mixins: [trialLanguagesMixin],
  computed: {
    languageText() {
      if (!this.appLanguageOptions) return this.appInfo.languageTag

      const found = this.appLanguageOptions.find(
        o => o.languageTag === this.appInfo.languageTag
      )

      return found ? found.name : this.appInfo.languageTag
    },

    timeFormatText() {
      if (!appTimeFormatOptions) return this.appInfo?.clockNotation

      const found = appTimeFormatOptions.find(
        o => o.value === this.appInfo.clockNotation
      )

      return found ? found.text : this.appInfo.clockNotation
    },
  },
  methods: {
    getLanguageText(from) {
      if (!this.appLanguageOptions) return from

      const found = this.appLanguageOptions.find(o => o.languageTag === from)

      return found ? found.name : from
    },

    getTimeFormatText(from) {
      if (!appTimeFormatOptions) return from

      const found = appTimeFormatOptions.find(o => o.value === from)

      return found ? found.text : from
    },
  },
})
