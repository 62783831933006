
import Vue from 'vue'
import SettingsHeader from '@/components/patient/settings/_SettingsHeader.vue'
import AppSettingsItems from './_AppSettingsItems.vue'
import EditCurrentAppSettings from './_EditCurrentAppSettings.vue'

export default Vue.extend({
  name: 'CurrentAppSettingsCard',
  props: {
    appInfo: { type: Object, required: true },
    patientNo: { type: String, required: true },
  },
  components: {
    SettingsHeader,
    AppSettingsItems,
    EditCurrentAppSettings,
  },
  methods: {
    reloadAppInfo() {
      this.$emit('reloadAppInfo')
    },
  },
})
