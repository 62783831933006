import { render, staticRenderFns } from "./CurrentAppSettingsCard.vue?vue&type=template&id=51ffe476&scoped=true"
import script from "./CurrentAppSettingsCard.vue?vue&type=script&lang=ts"
export * from "./CurrentAppSettingsCard.vue?vue&type=script&lang=ts"
import style0 from "./CurrentAppSettingsCard.vue?vue&type=style&index=0&id=51ffe476&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ffe476",
  null
  
)

export default component.exports