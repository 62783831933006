<template>
  <div>
    <v-row
      no-gutters
      class="py-4 settings-item d-flex align-center"
      data-testid="settings-item"
    >
      <v-col sm="4">{{ title }}: </v-col>
      <v-col class="pl-1"
        ><strong><slot /></strong
      ></v-col>
      <v-col sm="3" class="text-right">
        <slot name="action" />
      </v-col>
    </v-row>
    <v-row v-if="extraInfo" class="pa-4 info-background"
      ><slot name="info" />
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SettingsItem',
  props: {
    title: { type: String, required: true },
    extraInfo: { type: Boolean, required: false, default: false },
  },
}
</script>

<style lang="scss" scoped>
.settings-item {
  border-top: 1px solid $nn-D_T90;
}
.info-background {
  background-color: $nn-GS_T90;
}
</style>
